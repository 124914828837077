<template>
  <div class="entity-status-list d-flex align-center justify-space-between">
    <div class="entity-status-list__container d-flex flex-md-wrap">
      <router-link
        v-for="status in statuses"
        :key="status.name"
        exact-path
        class="entity-status-list__item text-h5 text-md-h4 font-weight-bold mr-4 mr-md-8 "
        active-class="entity-status-list__item--active"
        :to="{ name: status.name }"
      >
        <div class="d-flex align-center">
          <span class="entity-status-list__text">{{ status.text }}</span>
          <v-tooltip v-if="status.tooltip" bottom max-width="250">
            <template #activator="{ on, attrs }">
              <v-icon class="ml-1 entity-status-list__info " v-bind="attrs" v-on="on">
                mdi-information
              </v-icon>
            </template>

            <span>{{ status.tooltip }}</span>
          </v-tooltip>
        </div>
      </router-link>
    </div>

    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntityStatusList',

  inject: ['media'],

  props: {
    statuses: { type: Array, required: true },
  },
};
</script>

<style lang="scss">
.entity-status-list {
  &__item {
    display: block;
    color: rgba($--black-color-0, 0.5) !important;
    text-decoration: none !important;

    &--active {
      .entity-status-list__text {
        color: $--black-color-0 !important;
        text-decoration: underline !important;
      }
    }
  }

  &__container {
    overflow-x: auto;
  }

  &__info {
    color: rgba($--black-color-0, 0.5) !important;
  }

  &__text {
    white-space: nowrap;
  }
}
</style>
