<template>
  <div style="height: 100%">
    <base-dialog ref="deleteDialog" :title="$t('templates.deletion_confirmation')" />
    <div v-if="isLoading" class="d-flex justify-center align-center primary--text" style="height: 100%">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-row v-else-if="templates.length" class="mt-5 mt-md-10">
      <v-col v-for="template in templates" :key="template.id" cols="6" md="3">
        <v-hover v-slot="{ hover }" style="cursor: pointer">
          <v-card
            :elevation="hover ? 12 : 6"
            class="d-flex flex-column justify-center"
            @click.stop="editTemplate(template)"
          >
            <v-img :src="template.document.previewUrl" class="align-end">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
              <v-card-actions v-if="media.isMobile" class="justify-end">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <icon-button
                      color="white"
                      :width="32"
                      class="px-0 not-before"
                      style="outline: 1px solid #C7D6FF "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary" size="24">
                        mdi-dots-horizontal
                      </v-icon>
                    </icon-button>
                  </template>
                  <v-list>
                    <v-list-item @click="editTemplate(template)">
                      <v-list-item-title>
                        {{ $t('button.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="cloneTemplate(template)">
                      <v-list-item-title>
                        {{ $t('button.duplicate') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteTemplate(template)">
                      <v-list-item-title>
                        {{ $t('button.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
              <v-card-actions v-else class="template-actions justify-end" :class="hover ? 'show' : 'hide'">
                <icon-button
                  color="white"
                  :width="44"
                  class="px-0 not-before"
                  style="outline: 1px solid #C7D6FF "
                  @click.stop="cloneTemplate(template)"
                >
                  <v-icon color="primary" size="24">
                    mdi-content-copy
                  </v-icon>
                </icon-button>
                <icon-button
                  color="white"
                  :width="44"
                  class="px-0 not-before"
                  style="outline: 1px solid #C7D6FF "
                  @click.stop="editTemplate(template)"
                >
                  <v-icon color="primary" size="24">
                    mdi-pencil
                  </v-icon>
                </icon-button>
                <icon-button
                  color="white"
                  :width="44"
                  class="px-0 not-before"
                  style="outline: 1px solid #C7D6FF "
                  @click.stop="deleteTemplate(template)"
                >
                  <v-icon color="primary" size="24">
                    mdi-delete
                  </v-icon>
                </icon-button>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-hover>
        <p class="text-body2 text--md-h6 mt-2 mt-md-6 mb-0" style="word-break: break-all;">{{ template.name }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import contractService from '@/services/contract.js';
import IconButton from '@/components/IconButton.vue';
import notificationService from '@/services/notification';
import BaseDialog from '@/components/BaseDialog.vue';

import { TEMPLATES_EDIT } from '@/constants/routes';

export default {
  name: 'TemplatesList',
  inject: ['media'],
  components: { BaseDialog, IconButton },
  props: {},
  data() {
    return {
      isLoading: true,
      templates: null,
      isOpenMenu: false,
    };
  },

  created() {
    this.getTemplates();
  },

  methods: {
    getTemplates() {
      this.isLoading = true;

      contractService
        .getTemplates()
        .then(({ results }) => {
          this.templates = results;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    cloneTemplate(template) {
      this.$options.notificationItem = notificationService.info('templates.file_is_cloning', null, 350, true);
      contractService
        .cloneTemplate(template.id)
        .then(({ data }) => {
          this.templates.unshift(data);
        })
        .catch(() => {
          notificationService.remove(this.$options.notificationItem);
        })
        .finally(() => {
          notificationService.remove(this.$options.notificationItem);
          this.$options.notificationItem = null;
        });
    },

    editTemplate(template) {
      this.$router.push({ name: TEMPLATES_EDIT, params: { id: template.id } });
    },

    async deleteTemplate(template) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (!needDelete) return;

      contractService.deleteTemplate(template.id).then(() => {
        this.templates = this.templates.filter(t => t.id !== template.id);
        notificationService.success(this.$t('templates.template_is_deleted'), 2000);
      });
    },
  },
  notificationItem: null,
};
</script>
